

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-text">
        <p>Copyright &copy; 2024 by Marius Cornescu | All Rights Reserved.</p>
      </div>
      {/* <div className="footer-iconTop">
        <a href='#home'><i className="bx bx-up-arrow-alt"></i></a>
      </div> */}
    </footer>
  )
}