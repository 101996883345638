export const Services = () => {

  return (
    <section className='services' id='services'>
      <h2 className="heading">My <span>Skills</span></h2>
      <div className="services-container">
        <div className="services-box">
          <i className="bx bx-code-alt"></i>
          <h3>Web Development </h3>
          <p>Proficient FullStack web developer skilled in Node.js, React.js, and PHP. Experienced with APIs, CMS setups, and diverse web development tasks, ensuring seamless performance and optimal functionality of web applications</p>
          {/* <a href="/" className="btn">Read More</a> */}
        </div>
        <div className="services-box">
          <i className="bx bxs-paint"></i>
          <h3>Graphic Design </h3>
          <p>A skilled graphic designer with a keen eye for detail and a passion for creativity, specializing in crafting impactful visuals that resonate with audiences and elevate brands to new heights.</p>
          {/* <a href="/" className="btn">Read More</a> */}
        </div>
        <div className="services-box">
          <i className='bx bx-stats'></i>
          <h3>Data Analysis </h3>
          <p>Proficient data analyst with expertise in Excel, SQL, and data visualization. Skilled in statistical analysis, deriving insights, improving decision-making, and enhancing business performance through data-driven strategies and effective presentation of data.</p>
          {/* <a href="/" className="btn">Read More</a> */}
        </div>
        <div className="services-box">
          <i className="bx bx-bar-chart-square"></i>
          <h3>Project Management </h3>
          <p>Experienced project manager skilled in using tools like Jira and Trello. Proficient in coordinating teams, managing timelines, and ensuring project delivery. Expertise in streamlining processes, improving efficiency, and achieving project goals effectively</p>
          {/* <a href="/" className="btn">Read More</a> */}
        </div>
        <div className="services-box">
          <i className="bx bx-bar-chart-alt"></i>
          <h3>Digital Marketing </h3>
          <p>A proficient marketing specialist adept at strategizing, executing, and optimizing campaigns to drive brand awareness, engagement, and conversions, leveraging data-driven insights for effective audience targeting and growth.</p>
          {/* <a href="/" className="btn">Read More</a> */}
        </div>
        <div className="services-box">
          <i className='bx bx-user-voice'></i>
          <h3>Public Speaking </h3>
          <p>A dynamic trainer and presenter skilled in delivering engaging, informative sessions that empower audiences with knowledge and inspire action, fostering learning, professional development, and lasting impact.</p>
          {/* <a href="/" className="btn">Read More</a> */}
        </div>
        <div className="services-box">
          <i className='bx bx-trophy'></i>
          <h3>Leadership</h3>
          <p>
            Soft skills are essential qualities that define my professional approach, enhancing collaboration, communication, and overall performance. My effective verbal and written expression (communication) ensures clarity and impact in all interactions. I excel in problem-solving, analyzing issues to devise optimal solutions. Teamwork is a cornerstone, as I thrive in collaborative environments, fostering synergy and achieving shared goals. Adaptability is a strength, allowing me to navigate change and new challenges with ease. Empathy is central to my interactions, as I prioritize understanding and considering others' feelings. These skills not only shape my success but also contribute to a positive organizational culture and personal growth.
          </p>
          {/* <a href="/" className="btn">Read More</a> */}
        </div>
      </div>

    </section>
  )
}