import { useState } from "react";
// import contactImg from "../assets/img/contact-img.svg";
import 'animate.css';
// import TrackVisibility from 'react-on-screen';

export const Contact = () => {
  // console.log('reactapp_mail_user: ' + process.env.REACT_APP_MAILUSER)

  const mailServerUrl = process.env.REACT_APP_MAILSERVER
  const formInitialDetails = {
    appMailUser: process.env.REACT_APP_MAILUSER,
    appMailPass: process.env.REACT_APP_MAILPASS,
    defaultTo: process.env.REACT_APP_MAILTO,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Send message');
  const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value
    })
  }

  // console.log('reactapp_mail_user: ' + formInitialDetails.appMailUser)
  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText("Sending...");
    let response = await fetch(`${mailServerUrl}/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(formDetails),
    });
    setButtonText("Send message");
    let result = await response.json();
    setFormDetails(formInitialDetails);
    if (result.code === 200) {
      setStatus({ succes: true, message: 'Message sent successfully' });
    } else {
      setStatus({ succes: false, message: 'Something went wrong, please try again later.' });
    }
  };

  return (
    <section className="contact" id="contact">
{/*      <TrackVisibility>
        {({ isVisible }) =>
          <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us" />
        }
      </TrackVisibility>
      */}
      {/* <TrackVisibility> */}
      {/* {({ isVisible }) => */}
      {/* <div className={isVisible ? "animate__animated animate__fadeIn" : ""}> */}
      <div>
        <h2 className="heading">Contact <span>Me!</span></h2>
        <form onSubmit={handleSubmit}>
          <div className="input-box">
            <input type="text" value={formDetails.firstName} placeholder="First Name" onChange={(e) => onFormUpdate('firstName', e.target.value)} />
            <input type="text" value={formDetails.lastName} placeholder="Last Name" onChange={(e) => onFormUpdate('lastName', e.target.value)} />
          </div>
          <div className="input-box">
            <input type="email" value={formDetails.email} placeholder="Email Address" onChange={(e) => onFormUpdate('email', e.target.value)} />
            <input type="tel" value={formDetails.phone} placeholder="Phone No." onChange={(e) => onFormUpdate('phone', e.target.value)} />
          </div>
          <textarea cols="30" rows="6" value={formDetails.message} placeholder="Message" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
          <button type="submit" className="btn">{buttonText}</button>
          {
            status.message &&
            <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
          }
        </form>
      </div>
      {/* </div> */}
      {/* </TrackVisibility> */}
    </section>
  )
}