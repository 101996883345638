import { useEffect } from 'react'
// import logo from './logo.svg';

import './App.css'
// import 'bootstrap/dist/css/bootstrap.min.css';

import { Header } from "./components/Header"
import { Home } from "./components/Home"
import { About } from './components/About'
import { Services } from './components/Services'
import { Portfolio } from './components/Portfolio'
import { Contact } from './components/Contact'
import { Footer } from './components/Footer'

function App() {

  useEffect(() => {
    document.title = process.env.REACT_APP_TITLE
  }, []);

  return (
    <>
      <Header />
      <Home />
      <About />
      <Services />
      <Portfolio />
      <Contact />
      <Footer />
    </>
  );
}

export default App;