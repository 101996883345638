import { TypeAnimation } from 'react-type-animation';

export const Home = () => {

  return (
    <section className='home' id='home' name='home'>
      <div className="home-content">
        <h3>Hello, It's Me</h3>
        <h1>Marius Cornescu</h1>
        {/* <h3>And I'm a <span>FullStack Developer</span></h3> */}
        <h3>And I'm a &nbsp;
          <TypeAnimation
            sequence={[
              "FullStack Developer", 3000,
              "Graphic Designer", 3000,
              "Project Manager", 3000,
              "Public Speaker", 3000,
            ]}
            speed={50}
            // style={{}}
            repeat={Infinity}
          />
        </h3>
        <p>
          I'm a seasoned professional with over 20 years of experience in IT, specializing in Customer/OS/SW support, web development, and graphic design. I have a proven track record in product management and excel at developing go-to-market strategies. I have worked with cross-functional teams and have a strong background in technical solution management and system administration. I'm also involved in community projects like Brno Toastmasters.
        </p>
        <div className="social-media">
          <a href='https://www.facebook.com/yoshi.minowara' target="_blank" rel="noreferrer"><i className="bx bxl-facebook" /></a>
          <a href='https://www.linkedin.com/in/cornescumarius/' target="_blank" rel="noreferrer"><i className="bx bxl-linkedin" /></a>
        </div>
        <a href='https://1drv.ms/b/s!AlIjUF5vadeOr7QOXLxHd-4mNdoVKg?e=UVUqfN' target='_blank' rel='noreferrer' className="btn">Download CV</a>
      </div>

      <div className="home-img">
        <div className="hexagon">
          <img src='/images/eu3.png' alt='me' />
        </div>
      </div>
    </section>
  )
}