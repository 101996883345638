export const Portfolio = () => {

  return (
    <section className='portfolio' id='portfolio'>
      <h2 className="heading">Latest <span>Project</span></h2>

      <div className="portfolio-container">
        <div className="portfolio-box">
          <img src="images/projects/btweb.png" alt="webdev" />
          <div className="portfolio-layer">
            <h4>Web development</h4>
            <p>A Node.JS API with a React.JS front End app managing clubs or conferences activities</p>
            {/* <a href="/"><i className="bx bx-link-external"></i></a> */}
          </div>
        </div>
        <div className="portfolio-box">
          <img src="images/projects/acman.png" alt="rollout" />
          <div className="portfolio-layer">
            <h4>Marketing, Training</h4>
            <p>Promoted and implemented a new tool in Customer Centers around the world</p>
            {/* <a href="/"><i className="bx bx-link-external"></i></a> */}
          </div>
        </div>
        <div className="portfolio-box">
          <img src="images/projects/ph.png" alt="webdesign" />
          <div className="portfolio-layer">
            <h4>Web design</h4>
            <p>A simple HTML/CSS/JS landing page</p>
            {/* <a href="/"><i className="bx bx-link-external"></i></a> */}
          </div>
        </div>
        <div className="portfolio-box">
          <img src="images/projects/pm.png" alt="projectManagement" />
          <div className="portfolio-layer">
            <h4>Project Management</h4>
            <p>A github project board for a project currently in progress</p>
            {/* <a href="/"><i className="bx bx-link-external"></i></a> */}
          </div>
        </div>
        <div className="portfolio-box">
          <img src="images/projects/gd.png" alt="graphicDesign" />
          <div className="portfolio-layer">
            <h4>Graphic design</h4>
            <p>Using AI, photo manipulation and Canva for quick and clean results</p>
            {/* <a href="/"><i className="bx bx-link-external"></i></a> */}
          </div>
        </div>
        <div className="portfolio-box">
          <img src="images/projects/ps.png" alt="publicSpeaking" />
          <div className="portfolio-layer">
            <h4>Leadership & Public Speaking</h4>
            <p>President of Brno Toastmasters - Public speaking club - a comunity project</p>
            {/* <a href="/"><i className="bx bx-link-external"></i></a> */}
          </div>
        </div>
      </div>
    </section>
  )
}